import { graphql, useStaticQuery } from "gatsby"

export default function ThanksData() {
  return useStaticQuery(graphql`
    query thanksQueriesChoose {
      hero: sanityHero(slug: { current: { eq: "thanks" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      seo: sanitySeo(
        slug: { current: { eq: "thank-you-how-to-choose-a-developer" } }
      ) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
      }
    }
  `)
}
